<template>
<form @submit.prevent="handleSubmit">
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Create Project</p>
    </header>
    <section class="modal-card-body">
      <b-field
        label="Title"
        label-for="title"
        :type="{'is-danger': errors.has('title')}"
        :message="errors.first('title')">
        <b-input
          v-model="projectDTO.title"
          placeholder="e.g. Marketing, Operation, Frontend"
          id="title"
          name="title"
          v-validate="'required'"
          >
        </b-input>
      </b-field>
      <b-field
        label="Description"
        label-for="description">
        <b-input
          id="description"
          type="textarea"
          v-model="projectDTO.description">
        </b-input>
      </b-field>
      <color-picker-field
        :color="'#FB9E00'"
        v-model="projectDTO.color"
      />
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Close</button>
      <b-button class="button is-primary"
                native-type="submit" :loading="submitBtnLoading">Create</b-button>
    </footer>
  </div>
</form>
</template>

<script>
import ProjectModel from '@/models/project.model';
import ProjectService from '@/services/project.service';
import ColorPickerField from '../components/pureComponents/ColorPickerField.component.vue';

export default {
  components: {
    ColorPickerField,
  },
  data() {
    return {
      projectDTO: new ProjectModel(),
      submitBtnLoading: false,
    };
  },
  methods: {
    async handleSubmit() {
      this.submitBtnLoading = true;

      if (await this.isFormValid()) {
        this.createAProject();
      } else {
        this.submitBtnLoading = false;
        this.$buefy.toast.open({
          message: 'Form is not valid! Please check the fields.',
          duration: 3000,
          type: 'is-danger',
          position: 'is-bottom'
        });
      }
    },
    async isFormValid() {
      const isValid = await this.$validator.validateAll();
      return isValid;
    },
    createAProject() {
      const { projectDTO } = this;

      ProjectService.createProject(projectDTO)
        .then(() => {
          this.submitBtnLoading = false;
          this.$parent.close();
        })
        .catch(error => console.error(error));
    },
  },

};
</script>

<style>

</style>

<template>
  <div class="color-picker-field">
    <compact-picker
      v-if="displayPicker"
      :value="internalColor"
      @input="updateValue"
    />
    <b-field label="Color" label-for="color-picker-input" />
    <b-field>
      <b-button
        ref="colorButton"
        @click="togglePicker"
        icon-left="fill-drip"
        style="color: #FFF; background-color: #7B64FF;"
      />
      <b-input
        id="color-picker-input"
        @hook:mounted="childMounted"
        v-model="internalColor"
        readonly
        expanded
      >
      </b-input>
    </b-field>
  </div>
</template>

<script>
import { Compact } from 'vue-color';

export default {
  components: {
    CompactPicker: Compact,
  },
  props: {
    color: {
      type: String,
      default: '#7B64FF',
    },
  },
  data() {
    return {
      displayPicker: false,
      internalColor: this.color
    };
  },
  created() {
    this.$emit('input', this.internalColor);
  },
  methods: {
    updateValue(color) {
      this.internalColor = color.hex;

      this.$refs.colorButton.$el.style.backgroundColor = this.internalColor;
      this.$emit('input', this.internalColor);
    },
    togglePicker() {
      this.displayPicker = !this.displayPicker;
    },
    childMounted() {
      this.$refs.colorButton.$el.style.backgroundColor = this.internalColor;
    },
  },
};
</script>

<style lang="scss">
.color-picker-field {
  position: relative;
  .vc-compact {
    position: absolute;
    bottom: 42px;
    z-index: 10;
  }
}
</style>

<template>
<div class="projects">
  <section>
    <div class="content">
      <button class="button is-primary is-medium"
        @click="isComponentModalActive = true">
        Create a Project
      </button>
    </div>
    <b-modal :active.sync="isComponentModalActive"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal>
      <ModalForm></ModalForm>
    </b-modal>
    <div class="project-grid clearfix">
      <div
        class="project"
        v-for="userProject in ACTIVE_WORKSPACE_PROJECTS" v-bind:key="userProject._id"
        :class="[replaceHashWithUnderscore(userProject.color)]"
      >
        <div class="project-title">
          <div class="project-name">
            <h3>
              <!-- https://forum.vuejs.org/t/router-link-with-dynamic-id-in-v-for-loop/11040/5 -->
              <router-link :to="`/${ACTIVE_WORKSPACE.title}/projects/${userProject._id}`">
                {{userProject.title}}
              </router-link>
            </h3>
          </div>
          <div class="project-description">
            <p>{{userProject.description}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as PROJECT_CONSTANTS, } from '@/store/modules/project/project.constants';
import ProjectService from '@/services/project.service';
import { replaceHashWithUnderscore } from '../utils/utils';
import ModalForm from './CreateProjectForm.vue';

export default {
  components: {
    ModalForm
  },
  data() {
    return {
      isComponentModalActive: false,
    };
  },
  methods: {
    replaceHashWithUnderscore,
  },
  computed: {
    ...mapGetters([
      WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE,
      PROJECT_CONSTANTS.GETTERS.ACTIVE_WORKSPACE_PROJECTS,
    ]),
  },
  created() {
    ProjectService.requestAllProjects()
      .catch(error => console.error(error));
  }
};
</script>

<style lang="scss">
.project-grid {
  display: flex;
  flex-wrap: wrap;

  .project {
    background: #f3f3f3;
    border-radius: 5px;
    border-left-width: 8px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    float: left;
    margin: 0 14px 16px 0;
    height: 176px;
    opacity: 1;
    padding: 14px 16px 16px;
    position: relative;
    flex-basis: 330px;
    border-left: 5px solid #efb043;
  }
  .project-title {
    max-height: 100%;
    overflow-y: hidden;
    h3 {
      font-family: 'Open Sans',Helvetica,Arial,sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      margin: 0 14px 0 0;
      position: relative;
      a {
        color: #2c3e50;
      }
    }
  }
}

.clearfix:after {
  clear: both;
  content: '.';
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
}
</style>

import BaseService from '@/services/base.service';
import { asyncHandler } from '@/utils/utils';
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as PROJECT_CONSTANTS, } from '@/store/modules/project/project.constants';

class ProjectService extends BaseService {
  static requestAllProjects() {
    return this.$store.dispatch(WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_PROJECTS_REQUEST);
  }

  // static createProject = asyncHandler(
  //   (projectDTO) => {
  //     const a = this.$store.dispatch(PROJECT_CONSTANTS.ACTIONS.PROJECT_CREATE, { projectDTO });
  //     const b = this.$store.dispatch(WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_PROJECTS_REQUEST);
  //     return Promise.all(a, b);
  //   }
  // )

  static createProject = asyncHandler(
    async (projectDTO) => {
      await this.$store.dispatch(PROJECT_CONSTANTS.ACTIONS.PROJECT_CREATE, { projectDTO });
    }
  )

  static updateProject(projectDTO) {
    return this.$store.dispatch(PROJECT_CONSTANTS.ACTIONS.PROJECT_UPDATE, projectDTO);
  }
}

export default ProjectService;

import store from '@/store';
import { CONSTANTS as WORKSPACE_CONSTANTS } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';

class ProjectModel {
  constructor(options = {}) {
    const {
      _id,
      title,
      description,
      workspace,
      owner,
      members,
      color,
    } = options;

    this._id = _id;
    this.title = title;
    this.description = description;
    this.workspace = workspace || store.getters[WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE]._id;
    this.owner = owner || store.getters[USER_CONSTANTS.GETTERS.CURRENT_USER]._id;
    this.members = members;
    this.color = color;
  }
}

export default ProjectModel;
